import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import { environment } from 'src/environment/environment';
import { NewHttpService } from 'src/app/shared/services/http2.service';
import { LocalPathData } from 'src/app/shared/interfaces/common-store.interfaces';
import { PostApiResponse2 } from 'src/app/shared/interfaces/common-http.interfaces';

@Injectable({
  providedIn: 'root',
})
export class AuthServiceService {
  newAuthApi = environment.newPublicUrl;
  tokenExpire: any;

  constructor(
    private httpService: NewHttpService,
    private http: HttpClient,
    private router: Router,
    public jwtHelper: JwtHelperService,
    private newHttp: NewHttpService
  ) {}

  //intiallize
  int(systemData: any) {
    return this.http.post<LocalPathData<TokenInitResponseData>>(
      this.newAuthApi + 'token/Init',
      systemData
    );
  }

  // new intiallize
  intNew(systemData: UserAuthInfo) {
    return this.newHttp.postData<TokenInitResponseData>(
      'token/Init',
      { body: systemData },
      {
        allowSuccessMsg: false,
        withNewApi: true,
      }
    );
  }

  //sign in
  signIn(userData: any) {
    return this.http.post(this.newAuthApi + 'signin', userData);
  }

  // new sign in
  signInNew(userData: any) {
    return this.newHttp.postData<SignInResponseData>('signin', userData);
  }

  signOut() {
    return this.newHttp.delete<SignInResponseData>('signOut', null, {
      allowErrMsg: true,
      allowSuccessMsg: false,
      allowMsg: false,
      withoutAdminPreUrl: false,
      method: 'delete',
      withNewApi: true,
    });
  }

  //forgot Password
  forgotPasswordSendOtp(emial: string) {
    return this.httpService.postData<PostApiResponse2>(
      `forgotPassword`,
      {
        body: { email: emial },
      },
      {
        allowErrMsg: true,
        allowSuccessMsg: true,
        allowMsg: true,
        withNewApi: true,
        successMsg: 'OTP sent to the email successfully',
      }
    );
  }

  forgotPasswordVerifyOtp(data: any) {
    return this.httpService.postData<PostApiResponse2>(
      `ForgotPasswordVerifyOTP`,
      {
        body: data,
      },
      {
        allowErrMsg: true,
        withNewApi: true,
      }
    );
  }
  resetPassword(data: any) {
    return this.httpService.postData<PostApiResponse2>(
      `ResetPassword`,
      {
        body: data,
      },
      {
        allowErrMsg: true,
        allowSuccessMsg: true,
        allowMsg: true,
        withNewApi: true,
        successMsg: 'The Password reset successfully',
      }
    );
  }
  //--------------------------------------------------------
  //Dealing With Tokens;

  getToken() {
    return localStorage.getItem('token');
  }

  setToken(token: string): void {
    localStorage.setItem('token', token);
  }

  removeToken(): void {
    localStorage.clear();
  }

  public isAuthenticated(): boolean {
    const token = localStorage.getItem('token');
    if (token) {
      return !this.jwtHelper.isTokenExpired(token);
    }
    return false;
  }
}

interface UserAuthInfo {
  systemInfo: string;
  uuid: string;
}

export interface TokenInitResponseData {
  token: string;
}

interface SignInResponseData {
  otpId: string;
  token: string | null;
}
