<div class="flex align-items-center gap-2">
  @if (clearBtnVisible()) {
    <button pButton label="Clear" class="p-button-outlined" icon="pi pi-filter-slash" (click)="clearSearchInputValue()"></button>
  }
  <span class="p-input-icon-left ml-auto">
    <i class="pi pi-search"></i>
    <input 
      pInputText 
      class="w-full custom-width"
      type="text"
      [ngModel]="inputValue()"
      (ngModelChange)="inputValue.set($event)"
      #filter
      (keyup.enter)="onInputEvent($event.target!, 'enter')"
      (input)="onInputEvent($event.target!, 'input')"
      [placeholder]="searchInputPlaceHolder()" 
    />
  </span>
</div>