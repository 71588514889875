import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from './../../environment/environment';

@Injectable({
  providedIn: 'root',
})
export class InterceptorsService implements HttpInterceptor {
  constructor() {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const token: string | null = localStorage.getItem('token');

   const initAPI = environment.signInEndPoints;
   const isInitAPI= initAPI.some(endPoint => request.url.toLowerCase().includes(endPoint?.toString().toLowerCase()));

    request = request.clone({
      headers: request.headers.set('Authorization', environment.basicAuth)
    })
    if (token && !isInitAPI) {
      request = request.clone({
        headers: request.headers
          .set('token', token)
      });
    }

    request = request.clone({
      headers: request.headers
        .set('appcode', 'BNPL01')
        .set('Accept', 'application/json')
        .set('X-Frame-Options', 'SAMEORIGIN'),
    });

    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          //console.log('event--->>>', event);
        }
        return event;
      })
    );
  }
}
