import { CommonModule } from '@angular/common';
import { Component, OnInit, Input, Output, EventEmitter, signal, input, output } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';

@Component({
  selector: 'app-refresh-button',
  templateUrl: './refresh-button.component.html',
  styleUrls: ['./refresh-button.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    TooltipModule
  ]
})
export class RefreshButtonComponent {
  isLoading = input<boolean>(false);
  tooltipTxt = input<string>('Refresh', {
    alias: 'tooltip'
  }); // Default tooltip text
  refresh = output({
    alias: 'onClick'
  });

  styleClasses = input<string>(
    '', {
    alias: 'styleClass'
  });
  constructor() { }
}
