import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  OnChanges,
  SimpleChanges,
  ViewChild,
  forwardRef,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormsModule,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  ValidationErrors,
  FormControl,
} from '@angular/forms';
import { FileUpload, FileUploadModule } from 'primeng/fileupload';
import {
  Base64ImageData,
  FileUploadBody,
  FileUploaderApiConfig,
  UploadedFileInfo,
  UploadedFileInfoBase,
} from '../../interfaces/uploadfiles.interfaces';
import { HttpService } from '../../services/http.service';
import {
  _convertFileToBase64,
  _convert_File_To_Base64,
} from '../../utils/images.utils';
import {
  BehaviorSubject,
  Observable,
  catchError,
  tap,
  throwError,
  map,
  forkJoin,
  switchMap,
} from 'rxjs';
import { CheckExtensionPipe } from '../../pipes/checkExtension.pipe';
import { ButtonModule } from 'primeng/button';
import { TooltipModule } from 'primeng/tooltip';
import { ReplacePipe } from '../../pipes/replace.pipe';
import { ProgressBarModule } from 'primeng/progressbar';
import { ImageModule } from 'primeng/image';

@Component({
  selector: 'app-custom-uploader[apiConfig]',
  templateUrl: './custom-uploader.component.html',
  styleUrls: ['./custom-uploader.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FileUploadModule,
    FormsModule,
    ReactiveFormsModule,
    CheckExtensionPipe,
    ButtonModule,
    TooltipModule,
    ReplacePipe,
    ProgressBarModule,
    ImageModule,
  ],
  inputs: [
    'disabled',
    'multiple',
    'uploadLabel',
    'chooseLabel',
    'mode',
    'maxFileSize',
    'fileLimit',
    'accept',
    'required',
    'submitted',
    'uploadedFiles',
    'apiConfig',
    'uploadFilesTitle',
    'hint',
    'invalidFileSizeMessageSummary',
    'multiple',
    'formControlName',
    'formControl',
    'customValidator',
  ],
  outputs: ['onSelectFile', 'afterSuccessUpload', 'onClear', 'onRemove'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CustomUploaderComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: CustomUploaderComponent,
      multi: true,
    },
  ],
})
export class CustomUploaderComponent
  implements ControlValueAccessor, OnChanges
{
  // multi selection and upload
  multiple: boolean = false;
  fileLimit: number = 1;
  chooseLabel: string = 'Choose';
  uploadLabel: string = 'Upload';
  mode: 'basic' | 'advanced' = 'advanced';
  maxFileSize: number = 5000000;
  accept: string = 'image/png, image/jpeg, image/jpg';
  required: boolean = false;
  submitted: boolean = false;
  uploadFilesTitle = 'Uploaded Files :';
  hint: string = '*Supported format JPEG & PNG (max {maxFileSize}MB)';
  invalidFileSizeMessageSummary: string = 'Image should be max {maxFileSize}MB';
  disabled: boolean = false;

  formControlName!: string; // this is cancelled

  set formControl(formControl: FormControl<any> | AbstractControl<any>) {
    if (formControl) {
      this.referenceControl = formControl;
    }
  }
  referenceControl!: AbstractControl | FormControl;

  @ViewChild('fileUploader') fileUploader!: FileUpload;
  uploadedFiles: File[] = [];
  StoredFiles: BehaviorSubject<UploadedFileInfo[] | UploadedFileInfo | null> =
    new BehaviorSubject<UploadedFileInfo[] | UploadedFileInfo | null>([]);
  get storedFilesList(): UploadedFileInfo[] | UploadedFileInfo | null {
    return this.StoredFiles.getValue();
  }
  get storedFiles$(): Observable<UploadedFileInfo[] | UploadedFileInfo | null> {
    return this.StoredFiles.asObservable();
  }

  get storedFilesList$(): Observable<UploadedFileInfo[]> {
    return this.StoredFiles.asObservable().pipe(
      map((data: UploadedFileInfo[] | UploadedFileInfo | null) => {
        if (!data) return [] as UploadedFileInfo[];
        if (Array.isArray(data)) return data as UploadedFileInfo[];
        return [data] as UploadedFileInfo[];
      })
    ) as Observable<UploadedFileInfo[]>;
  }
  apiConfig!: FileUploaderApiConfig;

  //events emmitter
  onSelectFile = new EventEmitter<any>();
  afterSuccessUpload = new EventEmitter<any>();
  onClear = new EventEmitter<any>();
  onRemove = new EventEmitter<any>();

  uploadLoading: boolean = false;

  storedFilesChange$: Observable<UploadedFileInfo[] | UploadedFileInfo | null>;

  constructor(private _http: HttpService) {
    this.storedFilesChange$ = this.storedFiles$.pipe(
      tap((files) => {
        this.onChange(files);
      })
    );
  }

  selectFilesHandler(event: {
    originalEvent: Event;
    files: File[];
    currentFiles: File[];
  }) {
    this.onTouched();
    this.uploadedFiles = event?.currentFiles;
  }

  onChange: any = (value: any) => {};
  touched = false;

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }
  public onTouched = (): void => {
    this.touched = true;
  };
  writeValue(value: UploadedFileInfo[] | UploadedFileInfo) {
    if (value) {
      if (Array.isArray(value)) {
        if (!value?.length) {
          !this.multiple && this.StoredFiles.next(null);
          return;
        }
        if (this.multiple) {
          value = value.filter(
            (file) => file?.['fileName'] && file?.['fullLink'] && file?.['id']
          );
          this.StoredFiles.next(value);
          return;
        }
        value = value.filter(
          (file) => file?.['fileName'] && file?.['fullLink'] && file?.['id']
        );
        this.StoredFiles.next(value?.[0] || null);
        return;
      }
      if (value?.['fullLink'] && value?.['id']) {
        if (this.multiple) {
          this.StoredFiles.next([value]);
          return;
        }
        this.StoredFiles.next(value);
        return;
      } else {
        this.resetUploadedFiles();
      }
    } else {
      this.resetUploadedFiles();
      this.StoredFiles.next(null);
    }
  }
  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  validate(control: AbstractControl<any, any>): ValidationErrors | null {
    Object.keys(control.errors! || {}).forEach((key) => {
      if (key == 'required') {
        this.required = true;
      }
    });
    return null;
  }
  setDisabledState(isDisabled: boolean) {
    if (isDisabled) {
      this.disabled = true;
    } else {
      this.disabled = false;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['submitted']) {
      if (this.submitted && this.required && this.uploadedFiles.length == 0) {
      }
    }
  }

  onClearHandler() {
    this.uploadLoading = false;
    this.onTouched();
    this.uploadedFiles = [];
    this.fileUploader?.clearInputElement();
    this.fileUploader.uploading = false;
  }

  onRemoveHandler(event: { originalEvent: Event; file: File }) {
    this.onTouched();
  }

  uploadHandler() {
    if (!this.disabled && !this.uploadLoading) {
      this.onTouched();
      this.fileUploader.uploading = true;
      this.uploadLoading = true;

      const fileUploadObservables = this.uploadedFiles.map((file) =>
        _convertFileToBase64(file).pipe(
          catchError((err) => {
            this.uploadLoading = false;
            return throwError(() => new Error(err));
          }),
          switchMap((res) => {
            res = res as Base64ImageData | null;
            const bodyData: FileUploadBody = {
              data: res?.base64 as string,
              extensionTypeId: this.apiConfig.extentionType,
              fileTypeId: this.apiConfig.fileType,
            };

            return this.uploadFile(bodyData).pipe(
              tap((response) => {
                this.pushStoredFile(response.data);
              }),
              catchError((err) => {
                this.uploadLoading = false;
                this.fileUploader.uploading = false;
                return throwError(() => new Error(err));
              })
            );
          })
        )
      );

      forkJoin(fileUploadObservables).subscribe({
        complete: () => {
          this.resetUploadedFiles();
        },
      });
    }
  }

  uploadFile(bodyData: FileUploadBody): Observable<UploadedFileInfoBase> {
    return this._http.uploadFile<UploadedFileInfoBase>(bodyData).pipe(
      catchError((err) => {
        this.uploadLoading = false;
        this.fileUploader.uploading = false;
        return throwError(() => new Error(err));
      })
    );
  }
  resetUploadedFiles() {
    this.uploadedFiles = [];
    this.fileUploader && this.fileUploader?.clear();
    this.fileUploader && this.fileUploader?.clearInputElement();
    this.fileUploader && (this.fileUploader.uploading = false);

    this.uploadLoading = false;
  }

  pushStoredFile(file: UploadedFileInfo) {
    if (this.multiple) {
      if (Array.isArray(this.storedFilesList)) {
        const newList = [...(this.storedFilesList || []), file];
        this.StoredFiles.next(newList);
        return;
      }
      if (this.storedFilesList) {
        const newList = [this.storedFilesList, file];
        this.StoredFiles.next(newList);
        return;
      }

      this.StoredFiles.next([file]);
      return;
    }
    this.StoredFiles.next(file);
  }

  removeStoredFile(file: UploadedFileInfo, index: number) {
    this.onTouched();
    if (Array.isArray(this.storedFilesList)) {
      const oldList = [...this.storedFilesList];
      oldList.splice(index, 1);
      const newList = [...oldList];
      this.StoredFiles.next([...newList]);
      return;
    }
    if (this.storedFilesList && !this.multiple) {
      this.StoredFiles.next(null);
      return;
    }

    this.StoredFiles.next(this.multiple ? [] : null);
    return;
  }
}
