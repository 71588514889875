import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthServiceService } from './auth-service.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService  {

  constructor(private route : Router , private authService :AuthServiceService) { }

  canActivate() {
    console.log('AuthGuardService: ', [this.route.url])
    if(!this.authService.isAuthenticated()){
      this.route.navigate(['/auth/login']);
      return false;
    }
    return true;
  }
}
