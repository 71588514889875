import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, computed, effect, Input, input, model, OnInit, output, signal } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { Table } from 'primeng/table';

@Component({
  selector: 'app-custom-search-input',
  templateUrl: './custom-search-input.component.html',
  styleUrls: ['./custom-search-input.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    ButtonModule,
    InputTextModule,
    FormsModule
  ]
})
export class CustomSearchInputComponent {
  @Input('searchInputValue') set searchInputValue (value: string){
    this.inputValue.set(value);
    this.handleSearchEvent(value, 'set');
  }
  protected readonly inputValue = signal<string>('');
  private readonly lastFilterValue = signal<string>('');
  clearBtnVisible = input<boolean>(true);

  searchInputPlaceHolder = input<string>('Search ...');
  // local search at the passed table component or parent component search function
  tableSearch = input<boolean>(true);
  // data table
  dt = input<Table>();
  dataTableValueChange = input<any>([]);
  isLazy = input<boolean>(true);

  // search type event: 'enter' or 'input'
  searchTypeEvent = input<'enter'|'input'>('enter');

  searchInputValueChange = output<string>();

  constructor() { 
    effect(() => {
      this.dataTableValueChange();
      setTimeout(() => {
        this.applyLazyFilteration(undefined, this.dataTableValueChange());
      }, 200);
    })
  }

  onInputEvent(target: EventTarget, eventType: 'enter' | 'input'){
    const value = (target as HTMLInputElement).value
    this.handleSearchEvent(value, eventType);
  }

  // this function to handle the search search input events
  handleSearchEvent(searchValue: string, eventType: 'enter' | 'input' | 'set' | 'clear'): void {
    const isLocalSearch = this.dt() && this.tableSearch();
    const table = this.dt();
    const isMatchedEvent = this.searchTypeEvent() === eventType;

    // Handle clear event
    if (eventType === 'clear') {
      if (isLocalSearch) {
        this.clearTableFilteration();
      } else {
        this.searchInputValueChange.emit(searchValue);
      }
      return;
    }

    // Handle local table search
    if (isLocalSearch) {
      console.log('isLocalSearch', isLocalSearch);
      const isProccessedEvent = isMatchedEvent || eventType === 'set';
      if (searchValue && (isProccessedEvent)) {
        this.lastFilterValue.set(searchValue);
        if(this.isLazy()){
          this.applyLazyFilteration(searchValue);
          return;
        }
        table?.filterGlobal(searchValue, 'contains');
        return;
      } 
      if (isProccessedEvent) {
        // table?.clear();
        this.lastFilterValue.set(searchValue);
        this.applyLazyFilteration(searchValue);
        return;
      }
    }

    // Handle external search based on event type match
    if (isMatchedEvent) {
      this.searchInputValueChange.emit(searchValue);
    }
  }

  // this function to clear the search input
  clearSearchInputValue(){
    this.inputValue.set('')
    this.handleSearchEvent('', "clear");
  }

  clearTableFilteration(){
    this.lastFilterValue.set('');
    if(this.isLazy()){
      this.dt()?.reset();
      this.dt()!.filteredValue = null;
      // console.log('clearTableFilteration:', [this.dt()?.filteredValue, this.dt()?.value])
      return;
    }
    this.dt()?.clear();
  }

  applyLazyFilteration(searchValue: string = this.lastFilterValue(), dataTableValue?: any[]) {
    // const table: Table =  this.dt()!;
    if (!this.dt()! || !this.dt()!.value) return;

    const filterValue = this.lastFilterValue()?.trim()?.toLowerCase()!;
    const dataTableValue4Filter: any[] = (dataTableValue && Array.isArray(dataTableValue)) ? dataTableValue : this.dt()!.value;
    // Define the reusable filter function
    this.dt()!._filter = () => {
      if (!filterValue) {
        this.dt()!.filteredValue = null;
        return;
      }

      this.dt()!.filteredValue = this.dt()!.filterService.filter(
        dataTableValue4Filter,
        this.dt()!.globalFilterFields || Object.keys(this.dt()!.value[0] || {}),
        filterValue,
        'contains',
        'global'
      );
    };

    // Apply initial filteration
    this.dt()!._filter();
  }
}
