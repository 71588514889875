import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { JwtModule } from '@auth0/angular-jwt';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { InterceptorsService } from './interceptors/interceptors.service';
import { SharedModule } from './shared/shared.module';
import { ToastModule } from 'primeng/toast';


export function tokenGetter(){
  return localStorage.getItem("token");
}

@NgModule({ declarations: [
        AppComponent,
    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
        AppRoutingModule,
        SharedModule,
        BrowserAnimationsModule,
        JwtModule.forRoot({
            config: {
                tokenGetter: tokenGetter,
            }
        }),
        ToastModule], providers: [
        { provide: HTTP_INTERCEPTORS, useClass: InterceptorsService, multi: true },
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class AppModule { }
