import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { NgModule } from '@angular/core';
import { NavigationEnd, PreloadAllModules, Router, RouterModule, Routes } from '@angular/router';
import { AuthGuardService } from './authentication/Services/auth-guard.service';

const routes: Routes = [
  { 
    path: "auth", 
    loadChildren: () => import('./authentication/authentication.module').then(mod => mod.AuthenticationModule)
  },
  { 
    path: "pages", loadChildren: () => import('./pages/pages.module').then(mod => mod.PagesModule),
    canActivate: [AuthGuardService]
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: '/pages'
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      routes, {
      useHash: true,
      paramsInheritanceStrategy: 'always',
      preloadingStrategy: PreloadAllModules
    }
    )
  ],
  providers: [
    { provide: LocationStrategy, useClass: HashLocationStrategy }
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
  constructor(private router: Router) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        console.log('Navigation completed:', event.urlAfterRedirects);
        // You can add analytics tracking here
      }
    });
  }
}
