<p-button
    appendTo="body"
    [loading]="isLoading()"
    [pTooltip]="tooltipTxt()"
    [styleClass]="'p-button-md p-button-outlined ' + styleClasses()"
    tooltipPosition="top"
    icon="pi pi-refresh"
    (onClick)="refresh.emit()"
    >
</p-button>
